import React from 'react'
import Layout from '../components/layout'

import styles from '../assets/scss/templates/Banner.module.scss'

const NotFoundPage = () => (
    <Layout>
        <div id="NotFound" className={styles.NotFound} />
    </Layout>
)

export default NotFoundPage
